<template>
  <q-dialog v-model="props.dialog">
    <q-card>
      <div class="q-pa-xs">
        <q-list bordered separator>
          <q-item>
            <q-item-section>
              <q-input filled v-model="fecha" label="Fecha de la tarea">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                      <q-date v-model="fecha" mask="YYYY-MM-DD">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Cerrar" color="primary" flat></q-btn>
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-select dense v-model="user" :rules="[val => val && val.length > 0 || 'Selecciona']" :options="users" label="Para (Usuario)"></q-select>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-section>Definir tarea:</q-item-section>
              <q-input clearable dense v-model="tarea" autogrow></q-input>
            </q-item-section>
          </q-item>
          <q-item v-ripple>
            <q-item-section>
              <q-btn label="Cancelar" color="negative" @click="cancel"></q-btn>
            </q-item-section>
            <q-item-section>
              <q-btn label="Aceptar" color="primary" @click="send"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
const props = defineProps({
  dialog: false,
  subject: '',
  user: '',
  task: ''
})
const emit = defineEmits(["dialog"])
const global = inject("global")
const user = ref()
const users = ref()
const fecha = ref(global.data.getDate())
const tarea = ref()
function send() {
  emit("dialog", true)
  global.sql("/db/insert/Tareas", {
    data: {
      Fecha: fecha.value,
      De: global.user.name,
      Para: user.value,
      Asunto: props.subject,
      Tarea: tarea.value
    }
  })
}
function cancel() {
  user.value = ''
  tarea.value = ''

  emit("dialog", true)
}
function init() {}
onMounted(() => { init() })
watch(() => props.task, (newV, oldV) => {
  tarea.value = newV
})
</script>