<template>
  <div class="q-ma-xs q-pa-xs">
    <div class="row justify-center">
      <q-img src="../img/logo.png" style="height: 200px; max-width: 200px"></q-img>
    </div>
    <div v-if="showHome" class="q-ma-sm q-pa-sm q-gutter-sm">
      <q-banner v-if="global.user.bdg.rp == 0 && global.user.bdg.sp == 0 && global.user.bdg.tp == 0" rounded dense inline-actions class="text-black bg-positive">
        Hoy no tienes tareas para gestionar
      </q-banner>
      <q-banner v-if="global.user.bdg.rp > 0 || global.user.bdg.sp > 0 || global.user.bdg.tp > 0" rounded dense inline-actions class="text-black bg-warning">
        {{ global.user.name }}, estas son tus
        tareas a realizar el día de hoy.
      </q-banner>
      <div>
        <q-card class="my-card">
          <q-card-actions align="around">
            <q-avatar size="80px">
              <q-img :src="url"></q-img>
            </q-avatar>
            <q-separator vertical></q-separator>
            <q-btn to="/Recibos/PendientesHoy" outline color="primary" icon="receipt_long" label="Recibos hoy">
              <q-badge :color="global.user.bdg.rp > 0 ? 'red' : 'green'" floating>{{
                global.user.bdg.rp
              }}
              </q-badge>
            </q-btn>
            <q-separator vertical></q-separator>
            <q-btn to="/Tareas/TareasHoy" outline color="primary" icon="task_alt" label="Tareas hoy">
              <q-badge :color="global.user.bdg.tp > 0 ? 'red' : 'green'" floating>{{
                global.user.bdg.tp
              }}</q-badge></q-btn>
            <q-separator vertical></q-separator>
            <q-btn to="/Siniestros/SiniestrosHoy" outline color="primary" icon="healing" label="Siniestros hoy">
              <q-badge :color="global.user.bdg.sp > 0 ? 'red' : 'green'" floating>{{
                global.user.bdg.sp
              }}
              </q-badge>
            </q-btn>
          </q-card-actions>
        </q-card>
      </div>
      <div>
        <q-card class="my-card">
          <q-card-section dense horizontal>
            <q-card-section>
              Indicadores de mi actividad
            </q-card-section>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-actions align="around">
          </q-card-actions>
        </q-card>
      </div>
      <div>
        <q-card class="my-card">
          <q-card-section dense horizontal>
            <q-card-section>
              Información general Agencias
            </q-card-section>
          </q-card-section>
          <q-separator></q-separator>
          <q-card-actions align="around">
            <q-chip square>Recibos Pendientes</q-chip>
            <q-separator></q-separator>
            <q-chip square icon="home_work"> {{ rec.RV }} en Valls
            </q-chip>
            <q-chip square icon="home_work"> {{ rec.RR }} en Reus
            </q-chip>
          </q-card-actions>
        </q-card>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, onMounted, watch } from "vue"
import { useRoute } from "vue-router"
const route = useRoute();
const global = inject("global")
const url = ref()
const showHome = ref(false)
const rec = ref({ TP: 0, RP: 0, SP: 0 })
function init() {
  if (global.user.name !== false) {
    showHome.value = true
    url.value = new URL(`../img/${global.user.name}.jpg`, import.meta.url).href
    global.sql("/db/count/Recibos", { where: { Estado: "Pendiente", Mediador: 34764 } }, (r) => r.success ? rec.value.RV = r.data[0] : '')
    global.sql("/db/count/Recibos", { where: { Estado: "Pendiente", Mediador: 42886 } }, (r) => r.success ? rec.value.RR = r.data[0] : '')
    global.sql("/db/Todo/Tareas", { where: { Responsable1: global.user.name, Responsable2: global.user.name, Para: global.user.name } }, (r) => 
      r.success ? Object.assign(global.user.bdg, r.data[0]) : ''
    )
  } else showHome.value = false

}
onMounted(() => { init() })
watch([route, () => global.user.area], () => { init() });
</script>