<template>
  <div class="q-pa-md q-gutter-sm">
    <q-input ref="filterRef" dense filled v-model="filter" label="Buscar archivos...">
      <template v-slot:append>
        <q-icon v-if="filter !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
      </template>
    </q-input>
    <div class="col12">
      <q-tree dense accordion :nodes="nodes" node-key="path" label-key="label" v-model:selected="file" v-model:expanded="folder" :filter="filter" @update:selected="open" />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, watch } from "vue";
import { Notify } from "quasar";
import { useRoute } from "vue-router"
const route = useRoute();
const global = inject("global");
const file = ref(null);
const folder = ref(null)
const filter = ref('')
const filterRef = ref(null)
const nodes = ref([]);
// FUNCIONES
function init() {
  global.sql("/docs/run", {}, (r) => { nodes.value = r.data });
}
function open(target) {
  let ext = target.split('.').pop();
  if (!ext.includes("/") && !ext.includes("\\")) {
    let url = window.location.protocol + "//" + window.location.hostname + "/docs/open?file=" + target + "&ext=" + ext
    window.open(url, '_blank')
  } else
    Notify.create({ message: 'La Carpeta no se puede abrir', color: "info", timeout: 1000, });
}
function resetFilter() {
  filter.value = ''
  filterRef.value.focus()
}
onMounted(() => { init(); });
watch([route, () => global.user.area], () => { init() });
</script>