<template>
  <div class="q-pa-md q-gutter-sm">
    <q-toolbar class="shadow-2 rounded-borders">
      <q-toolbar-title>
        AÑO
      </q-toolbar-title>
      <q-select outlined dense v-model="year" :options="years()" @update:model-value="updateData()">
      </q-select>
      <q-space></q-space>
      <q-toolbar-title>
        MES
      </q-toolbar-title>
      <q-select outlined dense v-model="month" :options="months()" @update:model-value="updateData()">
      </q-select>
    </q-toolbar>
    <q-list bordered class="rounded-borders">
      <q-expansion-item expand-separator icon="euro" switch-toggle-side label="Valls">
        <template v-slot:header>
          <q-item-section>
            Valls
          </q-item-section>
          <q-item-section center>
            <div class="row items-center">
              <q-chip square color="green" icon="euro" text-color="white">
                {{ altasVallsA }}
              </q-chip>
              <q-chip square color="red" icon="euro" text-color="white">
                {{ bajasVallsA }}
              </q-chip>
            </div>
          </q-item-section>
          <q-item-section side>
            <div class="row items-center">
              <q-chip square color="green" icon="euro" text-color="white">
                {{ altasValls }}
              </q-chip>
              <q-chip square color="red" icon="euro" text-color="white">
                {{ bajasValls }}
              </q-chip>
            </div>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section>
            <highcharts :options="chartValls" style="height: 60vh">
            </highcharts>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item expand-separator icon="euro" switch-toggle-side label="Reus">
        <template v-slot:header>
          <q-item-section>
            Reus
          </q-item-section>
          <q-item-section center>
            <div class="row items-center">
              <q-chip square color="green" icon="euro" text-color="white">
                {{ altasReusA }}
              </q-chip>
              <q-chip square color="red" icon="euro" text-color="white">
                {{ bajasReusA }}
              </q-chip>
            </div>
          </q-item-section>
          <q-item-section side>
            <div class="row items-center">
              <q-chip square color="green" icon="euro" text-color="white">
                {{ altasReus }}
              </q-chip>
              <q-chip square color="red" icon="euro" text-color="white">
                {{ bajasReus }}
              </q-chip>
            </div>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section>
            <highcharts :options="chartReus" style="height: 60vh">
            </highcharts>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from "vue"
const global = inject("global")
const series = ref({});
const years = () => Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);
const year = ref(new Date().getFullYear())
const months = () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const month = ref(String(new Date().getMonth() + 1))
const altasValls = ref()
const altasVallsA = ref()
const bajasValls = ref()
const bajasVallsA = ref()
const altasReus = ref()
const altasReusA = ref()
const bajasReus = ref()
const bajasReusA = ref()
const chartValls = ref()
const chartVallsA = ref()
const chartReus = ref()
const chartReusA = ref()

// FUNCTION CHARTS
chartValls.value = {
  accessibility: { enabled: false },
  title: { text: "Valls" },
  chart: { type: 'column', zoomType: "xy" },
  xAxis: {
    title: { text: "Meses" },
    categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    crosshair: true,
  },
  yAxis: { min: 0, title: { text: 'Euros' } },
  tooltip: { valueSuffix: ' (€)', valueDecimals: 0 },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: { enabled: true, format: '{point.y:.0f}' }
    }
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Nueva Producción',
      data: series.value.NPV,
      color: "#4caf50"
    },
    {
      name: 'Anulaciones',
      data: series.value.ANV,
      color: "#f44336"
    }
  ]
}
chartReus.value = {
  accessibility: { enabled: false },
  title: { text: "Reus" },
  chart: { type: 'column', zoomType: "xy" },
  xAxis: {
    title: { text: "Meses" },
    categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    crosshair: true,
  },
  yAxis: { min: 0, title: { text: 'Euros' } },
  tooltip: { valueSuffix: ' (€)', valueDecimals: 0 },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: { enabled: true, format: '{point.y:.0f}' }
    }
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Nueva Producción',
      data: series.value.NPR,
      color: "#4caf50"
    },
    {
      name: 'Anulaciones',
      data: series.value.ANR,
      color: "#f44336"
    }
  ]
}

function getMonthsNames(numerosMeses) {
  const mesesCortos = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  return numerosMeses.map(numeroMes => mesesCortos[numeroMes - 1] || 'Inv');
};

function updateData() {

  global.sql("/db/ResumenAltas/Negocio", { where: { year: year.value } }, (res) => {
    if (res.success) {
      let med = global.data.getRows(res.data, "Mediador", 34764)
      chartValls.value.series[0].data = global.data.getColumn(med, "Importe")
      altasValls.value = global.data.get(med, "Mes", Number(month.value), "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      altasVallsA.value = global.data.getSum(med, "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      med = global.data.getRows(res.data, "Mediador", 42886)
      chartReus.value.series[0].data = global.data.getColumn(med, "Importe")
      altasReus.value = global.data.get(med, "Mes", Number(month.value), "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      altasReusA.value = global.data.getSum(med, "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
    }
  })
  global.sql("/db/ResumenBajas/Negocio", { where: { year: year.value } }, (res) => {
    if (res.success) {
      let med = global.data.getRows(res.data, "Mediador", 34764)
      chartValls.value.series[1].data = global.data.getColumn(med, "Importe")
      bajasValls.value = global.data.get(med, "Mes", Number(month.value), "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      bajasVallsA.value = global.data.getSum(med, "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      med = global.data.getRows(res.data, "Mediador", 42886)
      chartReus.value.series[1].data = global.data.getColumn(med, "Importe")
      bajasReus.value = global.data.get(med, "Mes", Number(month.value), "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
      bajasReusA.value = global.data.getSum(med, "Importe").toLocaleString('es-ES', { maximumFractionDigits: 0 });
    }
  })
}

onMounted(() => {
  updateData()
})
</script>
