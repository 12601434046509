<template>
  <q-dialog v-model="props.dialog">
    <q-card>
      <div class="q-pa-xs">
        <q-list bordered separator>
          <q-item>
            <q-item-section>
              <q-input filled v-model="extrafilename" label="Referencia personal del archivo">
              </q-input>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-uploader :url="url" auto-upload label="Subir archivos" field-name="file"
                :form-fields="[{ name: 'folder', value: folder }, { name: 'name', value: file + extrafilename }]" multiple @uploaded="onUpload"
                style="max-width: 300px"></q-uploader>
              <!-- <q-uploader :url="url" auto-upload label="Subir archivos" field-name="file" :form-fields="[{ name: 'name', value: props.table }]" multiple @uploaded="onUpload" style="max-width: 300px"></q-uploader> -->
            </q-item-section>
          </q-item>
          <q-item v-ripple>
            <q-item-section>
              <q-btn label="Cerrar" color="primary" @click="close"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { inject, onMounted, ref, watch, computed } from 'vue';
import { Notify } from 'quasar'
const props = defineProps({
  dialog: false,
  folder: '',
  file: null
})
const emit = defineEmits(["dialog"])
const global = inject("global")
const extrafilename = ref('')
const url = window.location.protocol + "//" + window.location.hostname + "/docs/upload"

function close() {
  emit("dialog", true)
}
function onUpload(info) {
  let res = JSON.parse(info.xhr.response);
  if (res.success == true) {
    Notify.create({
      message: "Archivo subido correctamente",
      color: "positive",
      position: "bottom",
    });
  } else {
    Notify.create({
      message: res.message,
      color: "negative",
      position: "bottom",
    });
  }
}
function init() { }
onMounted(() => { init() })
</script>