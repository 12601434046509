<template>
  <div class="q-pa-md row">
    <q-banner rounded class="bg-secondary text-black">
      La gestión de usuarios se realiza íntegramente en el servidor Synology.
      Asignar oficinas y niveles desde Synology Active Directory
    </q-banner>
  </div>
  <div class="q-pa-md row">
    <div class="col-xs-12 col-sm-4">
      <q-list bordered padding>
        <q-item-label header>Gestión de Usuarios</q-item-label>
        <q-item clickable v-ripple>
          <q-item-section>
            <q-select v-model="user" :options="users" @update:model-value="getUserData" label="Seleciona Usuario"></q-select>
          </q-item-section>
        </q-item>
        <div v-if="showUserInfo">
          <q-separator spaced></q-separator>
          <q-item-label header>Derogar por</q-item-label>
          <q-item clickable v-ripple>
            <q-item-section>
              <q-select v-model="userTemp" :options="users" @update:model-value="setUser" label="Reemplazado por">
                <template v-if="userTemp" v-slot:append>
                  <q-icon name="cancel" @click.stop.prevent="userTemp = null; setUser()" class="cursor-pointer"></q-icon>
                </template>
              </q-select>
            </q-item-section>
          </q-item>
        </div>
      </q-list>
    </div>
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from "vue";
import { Notify } from "quasar";
// CONSTANTES
const global = inject("global")
const user = ref();
const userTemp = ref();
const users = ref();
const showUserInfo = ref(false)

function setUser() {
  global.sql("/db/update/Usuarios", {
    data: {
      UsuarioTemp: userTemp.value,
    }, where: { Usuario: user.value }
  },
    (res) => {
      if (res.success)
        Notify.create({ message: "Cambios guardados", color: "green", });
    })
}

function getUserData(id) {
  user.value = id
  global.sql("/db/select/Usuarios", { where: { Usuario: user.value, Activo: 1 } },
    (res) => {
      if (res.success) {
        userTemp.value = (res.data[0].UsuarioTemp ?? "")
      }
    }).then(() => { showUserInfo.value = true })
}

onMounted(() => {
  global.sql("/db/select/Usuarios", {}, (r) => {
    if (r.success) {
      users.value = global.data.getColumn(r.data, "Usuario")
    }
  })
})
</script>