<template>
  <div class="q-mx-xs q-mt-xs">
    <div class="q-pa-md q-gutter-sm">
      <q-banner v-if="cliente.Pro == 1" inline-actions rounded class="bg-orange text-white">
        No debes gestionar este recibo ya que es de un colaborador profesional
      </q-banner>
    </div>
    <BajaPoliza :dialog="DBPonoff" :idpoliza="idpoliza" :fecha="fechaPol" :poliza="poliza" @dialog="DBPonoff = false"></BajaPoliza>
    <q-chip v-if="cliente.Banco" square color="primary" text-color="white" icon="account_balance">{{ cliente.Banco }}<q-tooltip>Cuenta
        Bancaria</q-tooltip>
    </q-chip>
    <q-chip v-if="cliente.Correo" square color="primary" text-color="white" icon="mail">{{ cliente.Correo }}<q-tooltip>Correo</q-tooltip>
    </q-chip>
    <q-chip v-if="cliente.Movil" square color="primary" text-color="white" icon="call">{{ cliente.Movil }}<q-tooltip>Telf Movil</q-tooltip>
    </q-chip>
    <q-chip v-if="cliente.Fijo" square color="primary" text-color="white" icon="tty">{{ cliente.Fijo }}<q-tooltip>Telf Fijo</q-tooltip>
    </q-chip>
    <q-chip v-if="cliente.SM" square color="primary" text-color="white" icon="co_present">{{ cliente.SM }}<q-tooltip>Colaborador</q-tooltip>
    </q-chip>
    <q-scroll-area style="height:  85vh;" class="shadow-2 rounded-borders">
      <q-stepper v-model="step" vertical color="primary" animated class="q-ma-xs">
        <template v-for="s in steps">
          <q-step :name="s.id" :title="s.tt" :icon="s.ic" :done-icon="s.ic" :done="step > s.id" done-color="positive">
            <span v-html="s.ta"></span>
            <!-- COPIAR PLANTILLA WHATSAPP -->
            <div v-if="s.id == 0">
              <q-btn round flat dense @click="copyToClipboard(pasos.WhatsApp)" icon="content_copy">
                <q-tooltip>Copiar plantilla predefinida y pegar en el chat de WhatsApp</q-tooltip>
              </q-btn>
            </div>
            <!-- SUBIR CARTA DE BAJA -->
            <div v-if="s.id == 50">
              <q-card class="row">
                <q-card-actions vertical>
                  <q-btn color="primary" label="Gestionar Baja" @click="DBPonoff = true"></q-btn>
                </q-card-actions>
              </q-card>
            </div>
            <!-- DECISION CLIENTE -->
            <div v-if="s.id == 30">
              <q-card class="row">
                <q-card-actions vertical>
                  <template v-for="opts in options">
                    <q-radio v-model="option" :val="opts.val" :label="opts.label"></q-radio>
                  </template>
                </q-card-actions>
              </q-card>
              <!-- CALENDARIO DE REAGENDA DE RECIBO -->
              <q-btn class="q-mx-xs q-py-xs" icon="calendar_month" color="primary" flat round>
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="datt" minimal>
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Cancelar" color="warning" flat></q-btn>
                      <q-btn v-close-popup label="Aceptar" color="primary" flat></q-btn>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-btn>
              <!-- AGENDAR NUEVA FECHA PARA EL PAGO CON EL CLIENTE-->
              <template v-if="option == 3">
                <b><u>SELECCIONAR CONCERTADA CON CLIENTE:</u></b> {{ date.formatDate(datt, 'DD/MM/YYYY') }}
                <q-btn class="q-mx-xs q-py-xs" icon="calendar_month" color="primary" flat round>
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="datt" minimal>
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Cancelar" color="warning" flat></q-btn>
                        <q-btn v-close-popup label="Aceptar" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-btn>
              </template>
            </div>
            <br>
            <span v-html="s.tb"></span>
            <q-stepper-navigation class="q-mx-xs q-py-xs">
              <div>
                <q-btn v-if="s.ss > 0" @click="setStep(s.ss, s.id, s.tt, s.ds, s.rs)" :label="s.ts" color="primary" style="width: 150px"></q-btn>
                <q-btn v-if="s.nn > 0" @click="setStep(s.nn, s.id, s.tt, s.dn, s.rn)" :label="s.tn" color="secondary" style="width: 150px"></q-btn>
                <q-btn icon="note_alt" color="primary" flat round style="width: 40px">
                  <q-tooltip> Escribir comentarios </q-tooltip>
                  <q-popup-proxy cover transition-show="scale">
                    <q-editor v-model="edit" min-height="5rem"></q-editor>
                    <q-separator></q-separator>
                    <q-btn class="absolute-bottom-right" v-close-popup label="ACEPTAR" color="primary"></q-btn>
                  </q-popup-proxy>
                </q-btn>
                <!-- Agenda personalizada -->
                <q-btn class="q-mx-xs q-py-xs" icon="calendar_month" color="primary" flat round>
                  <q-tooltip> Agenda Personalizada </q-tooltip>
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="datt" minimal>
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Cancelar" color="warning" flat></q-btn>
                        <q-btn v-close-popup label="Aceptar" color="primary" flat></q-btn>
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-btn>
                <q-btn v-if="s.id > 0" @click="setStep(s.vv, s.id, 'Corregir', 0, '')" color="dark" icon="arrow_back" flat round class="q-mx-xs q-py-xs">
                  <q-tooltip>Volver a última acción</q-tooltip></q-btn>
              </div>
            </q-stepper-navigation>
          </q-step>
        </template>
      </q-stepper>
    </q-scroll-area>
  </div>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';
import { date, copyToClipboard } from 'quasar';
import BajaPoliza from "@/components/BajaPoliza.vue"
import pasos from "@/components/RecibosGestion.json"
const emit = defineEmits(["volver"]);
//DEFAULTS
const global = inject("global")
// useFormChild({validate})
const props = defineProps({ tab: '', opts: {} })
// RECIBOS GESTION
const cliente = ref({ Banco: null, Cliente: null, Correo: null, Movil: null, Fijo: null, SM: null, Pro: null })
const steps = ref()
const step = ref()
const resp = ref()
const desc = ref()
const datt = ref(false)
const telf = ref(0)
const wapp = ref(0)
const option = ref(0)
const edit = ref('')
const options = ref([
  { val: 1, label: "POST: Registrar en AIRE ajuste automático con límites de compañía" },
  { val: 2, label: "AJUSTE: Hablar con sucursal y valorar opciones [Escribir comentarios]" },
  { val: 3, label: "PAGO OFICINA: Agendar fecha de pago del cliente" },
  { val: 4, label: "PAGO TARJETA/BIZUM: Confirmar pago " },
  { val: 5, label: "PAGO TRANSFERENCIA: Facilitar numero cuenta al cliente " },
  { val: 6, label: "PAGO ANTICIPO TRANSFERENCIA: Facilitar numero cuenta al cliente" },
  { val: 7, label: "ENVIO A BANCO: Confirmar cobro por banco" },
  { val: 8, label: "FACTURA ELECTRONICA" },
])
//BAJAPOLIZA
const DBPonoff = ref(false)
const idpoliza = ref(props.opts.id)
const poliza = ref(props.opts.Poliza)
const fechaPol = ref(props.opts.Fecha)
// FUNCIONES
function setStep(now, old, tt, dd, res) {
  if (now == 40 && props.opts.Gestor !== 'BA') now = 50 // Si No hay banco pasa a anulacion
  step.value = now
  let nPaso = now
  // pone fecha agenda por defecto
  if (datt.value === false)
    datt.value = global.data.getDate(dd)
  else
    edit.value = edit.value + "[AGENDADO Manualmente]" // si se ha agendado manualmente lo añade a edit

  // guardamos paso y selección del paso 30 (31,32,33...)
  if (now == 30)
    nPaso = parseInt("3" + option.value) //nPaso es 3+x que será 3x (por ej 3+2 = 32)
  // guarda la descripcion del titulo y la respuesta del paso
  desc.value = tt;
  resp.value = res;
  // Si estamos en el paso 30, guardaremos el texto del paso seleccionado
  if (now >= 30 && now < 40) {
    nPaso == 31 ? resp.value += " - Post" : "";
    nPaso == 32 ? resp.value += " - Ajuste" : "";
    nPaso == 33 ? resp.value += " - Pago Oficina" : "";
    nPaso == 34 ? resp.value += " - Pago tarjeta/Bizum" : "";
    nPaso == 35 ? resp.value += " - Pago transferencia" : "";
    nPaso == 36 ? resp.value += " - Pago anticipo transferencia" : "";
    nPaso == 37 ? resp.value += " - Envio Banco" : "";
    nPaso == 38 ? resp.value += " - Factura electrónica" : "";
  }
  // Guarda en la base de datos
  global.sql("/db/insert/RecibosGestion", {
    data: {
      Paso: nPaso,
      Recibo: props.opts.Recibo,
      Poliza: props.opts.Poliza,
      Descripcion: desc.value,
      Respuesta: resp.value,
      Usuario: global.user.name,
      FechaAgenda: datt.value,
      Comentarios: edit.value
    }
  }).then(() => {
    datt.value = false
    edit.value = ''
    resp.value = null
    telf.value = 0
    wapp.value = 0
  })
  // generamos una tarea segun el paso donde estemos
  if (nPaso == 31) {
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(0),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Generar POST en Aire del recibo " + props.opts.Recibo
      }
    })
  }
  if (nPaso == 32) {
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(0),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Gestionar con sucursal hacer un ajuste en el recibo " + props.opts.Recibo + " e informar al cliente de la decisión final"
      }
    })
  }
  if (nPaso == 35 || nPaso == 36) {
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(1),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Comprobar si ha llegado la transferencia del recibo " + props.opts.Recibo + " del cliente " + props.opts.Cliente
      }
    })
  }
  if (nPaso == 37) {
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(3),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Comprobar que el recibo " + props.opts.Recibo + " del cliente " + props.opts.Cliente + " se ha cobrado por el banco"
      }
    })
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(0),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Enviar recibo " + props.opts.Recibo + " del cliente " + props.opts.Cliente + " al banco de nuevo por petición cliente"
      }
    })
  }
  if (nPaso == 99) {
    global.sql("/db/insert/Tareas", {
      data: {
        Fecha: global.data.getDate(0),
        De: global.user.name,
        Para: global.user.name,
        Tarea: "Dar de baja en Aire/Aqua la póliza " + props.opts.Poliza + " del cliente " + props.opts.Cliente
      }
    })
  }
  // Si hay dias de espera salimos
  if (dd > 0) emit("volver", true)
  // volvemos a recibos si estamos sobre el mismo paso
  if (now == old)
    if (now == 30 || now == 40 || now == 50)
      emit("volver", true)
}

// FUNCTIONS

async function init() {
  await global.sql("/db/select/Clientes", { where: { Cliente: props.opts.Cliente, } }, (r) => {
    if (r.data.length > 0)
      cliente.value = r.data[0]
    else
      cliente.value = { Cliente: null, Correo: null, Movil: null, Fijo: null, SM: null, Banco: null, Pro: null }
  })
  let ss = props.opts.Paso
  // Limpiamos el valor de comentarios 'edit'
  edit.value = ""
  // aqui obtenemos el valor del step con multiopciones
  if (ss >= 30 && ss < 40) {
    step.value = 30
    option.value = ss % 10; // si hay 34 = 4, 32=2 , último dígito
  } else {
    step.value = ss
    option.value = 0
  }
  // los steps los cargamos el archivo json de pasos
  steps.value = pasos.cliente
  // los pasos 200 son para SM profesionales
  // los pasos 255 son para recibos caducados y sacados manualmente del sistema.
}
onMounted(() => { init() })
watch(() => props.tab, (n, o) => { if (props.tab == "tabA" && n !== o) init() })
// watch(() => props.opts, () => { init() })
</script>

<style>
.scroll {
  overflow: hidden;
}

.scroll.relative-position.fit.hide-scrollbar {
  overflow: scroll
}
</style>